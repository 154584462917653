<template>
  <AvatarTraffic :message="source.name" :src="source.src" size="20" />
</template>

<script>
import AvatarTraffic from "@/components/common/AvatarTraffic.vue";
import { mapGetters } from "vuex";
export default {
  name: "AvatarSource",
  components: {
    AvatarTraffic
  },
  props: {
    name: String
  },
  computed: {
    ...mapGetters("traffics", ["sourceByName"]),
    source() {
      const source = this.sourceByName(this.name.toLowerCase());
      const { media, name } = source;
      return { src: media.src, name };
    }
  }
};
</script>
