<template>
  <vue-funnel-graph
    :width="width"
    :height="height"
    :labels="labels"
    :values="values"
    :colors="colors"
    :sub-labels="subLabels"
    :direction="direction"
    :gradient-direction="gradientDirection"
    :animated="true"
    :display-percentage="true"
    :sub-label-value="subLabelValue"
  ></vue-funnel-graph>
</template>

<script>
import { VueFunnelGraph } from "vue-funnel-graph-js";

export default {
  name: "GraphicsCampaingReportV2",
  components: {
    VueFunnelGraph
  },
  props: {
    valuesGraphics: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      labels: ["TOTAL", "TOF", "MOF", "BOF"],
      subLabels: ["Error"],
      values: [[2, 2, 2]],
      colors: [
        ["#FFB178"],
        ["#A0BBFF"],
        ["#A0F9FF"],

        ["#ff6961"],
        ["#77dd77"],
        ["#fdfd96"],

        ["#84b6f4"],
        ["#fdcae1"],
        ["#ab4bde"],

        ["#1e91ed"],
        ["#15ab92"],
        ["#f3aacb"],

        ["#9ddbc9"],
        ["#CFE6A5"],

        ["#FF7F50"],
        ["#5F9EA0"],
        ["#8A2BE2"],

        ["#CD5C5C"],
        ["#32CD32"],
        ["#FFFF00"],

        ["#4682B4"],
        ["#FF69B4"],
        ["#9370DB"],

        ["#00CED1"],
        ["#7FFF00"],
        ["#FA8072"],

        ["#B0E0E6"],
        ["#DA70D6"]
      ],
      subLabelValue: "raw",
      direction: "horizontal",
      gradientDirection: "horizontal",
      height: window.screen.availWidth * 0.75 * 0.4,
      width: window.screen.availWidth * 0.75
    };
  },
  mounted() {
    this.mapData();
  },
  watch: {
    valuesGraphics() {
      this.mapData();
    }
  },
  methods: {
    mapData() {
      const result = {};
      this.valuesGraphics.forEach(item => {
        item.rows.forEach(row => {
          const { source, active } = row;
          if (!result[source]) {
            result[source] = { TOTAL: 0, TOF: 0, MOF: 0, BOF: 0 };
          }
          result[source].TOTAL += active.tof + active.mof + active.bof;
          result[source].TOF += active.tof;
          result[source].MOF += active.mof;
          result[source].BOF += active.bof;
        });
      });
      const subLabels = Object.keys(result);
      const data = this.labels.map(item =>
        Object.keys(result).map(key => result[key][item])
      );
      this.subLabels = subLabels;
      this.values = data;
    },
    rotateGraphic() {
      if (this.direction === "vertical") {
        this.height = window.screen.availWidth * 0.8 * 0.4;
        this.width = window.screen.availWidth * 0.8;
        this.direction = "horizontal";
        this.gradientDirection = "horizontal";
      } else {
        this.height = window.screen.availWidth * 0.75 * 0.4;
        this.width = window.screen.availWidth * 0.75;
        this.direction = "vertical";
        this.gradientDirection = "vertical";
      }
    }
  }
};
</script>

<style>
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 18px;
  font-weight: bold;
  color: #311b92;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  color: #262d3c;
}
.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
  color: black !important;
  font-size: 16px;
}
.svg-funnel-js .svg-funnel-js__subLabels {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}
</style>
