<template>
  <AvatarTraffic :message="medium.name" :src="medium.src" size="20" />
</template>

<script>
import AvatarTraffic from "@/components/common/AvatarTraffic.vue";
import { mapGetters } from "vuex";
export default {
  name: "AvatarMedium",
  components: {
    AvatarTraffic
  },
  props: {
    name: String
  },
  computed: {
    ...mapGetters("traffics", ["mediumByName"]),
    medium() {
      const medium = this.mediumByName(this.name.toLowerCase());
      const { media, name } = medium;
      return { src: media.src, name };
    }
  }
};
</script>
